<div class="filter-card">
    <div [@fadeIn] *ngIf="!isLoader; else Skeleton" class="label">{{label}}</div>
    <div [@fadeIn] [@toggleExpand]="showAll ? 'expanded' : 'collapsed'" [ngClass]="{'check-box-section-hover': isHover}"
        (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()" class="check-box-section"
        *ngIf="!isLoader; else FilterCardSkeleton">
        <div class="aggrigation-data" [ngClass]="{'checked-filter': data.isChecked}" *ngFor="let data of filterData">
            <div class="data-container">
                <mat-checkbox [disabled]="data.count == 0" [checked]="data.isChecked"
                    (change)="selectedFilter(data, label)">
                    <div>
                        <span class="data-section">{{ data.name }}</span>
                    </div>
                </mat-checkbox>
            </div>
            <div class="aggrigation-data-count">
                <span class="count">{{ data.count }}</span>
            </div>
        </div>
    </div>
    <div class="view-more-section" *ngIf="filterList.length > 5 && !isLoader ">
        <span class="icon-part" (click)="toggleViewMore()">
            <i class="material-icons">{{ showAll ? 'keyboard_double_arrow_up' : 'keyboard_double_arrow_down' }}</i>
        </span>
    </div>
</div>

<ng-template #Skeleton>
    <div class="label">
        <kendo-skeleton shape="text" animation="pulse" width="50%"></kendo-skeleton>
    </div>
</ng-template>

<ng-template #FilterCardSkeleton>
    <div class="check-box-section">
        <div class="aggrigation-data-skeleton">
            <kendo-skeleton shape="rectangle" animation="pulse" [width]="15" height="15px"></kendo-skeleton>
            <kendo-skeleton shape="text" animation="pulse" width="90%"></kendo-skeleton>
        </div>
        <div class="aggrigation-data-skeleton">
            <kendo-skeleton shape="rectangle" animation="pulse" [width]="15" height="15px"></kendo-skeleton>
            <kendo-skeleton shape="text" animation="pulse" width="80%"></kendo-skeleton>
        </div>
        <div class="aggrigation-data-skeleton">
            <kendo-skeleton shape="rectangle" animation="pulse" [width]="15" height="15px"></kendo-skeleton>
            <kendo-skeleton shape="text" animation="pulse" width="90%"></kendo-skeleton>
        </div>
        <div class="aggrigation-data-skeleton">
            <kendo-skeleton shape="rectangle" animation="pulse" [width]="15" height="15px"></kendo-skeleton>
            <kendo-skeleton shape="text" animation="pulse" width="80%"></kendo-skeleton>
        </div>
        <div class="aggrigation-data-skeleton">
            <kendo-skeleton shape="rectangle" animation="pulse" [width]="15" height="15px"></kendo-skeleton>
            <kendo-skeleton shape="text" animation="pulse" width="90%"></kendo-skeleton>
        </div>
    </div>

</ng-template>