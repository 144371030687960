<!-- input search field section -->
<div class="global-main-section" kendoPopoverAnchor [popover]="myPopover" showOn="none" #anchor="kendoPopoverAnchor">
    <div class="global-search-input-section"
        [ngClass]="{'global-main-section-border': globalSearchFormControl?.value?.length > 3}">
        <div class="label-section">{{ 'LABEL.QUICK_ORDER_SEARCH' | translate }}:</div>
        <div class="input-section">
            <mat-form-field appearance="outline">
                <mat-icon id="btnSearch" matPrefix>search</mat-icon>
                <input #searchInput appAutofocus type="text" matInput [formControl]="globalSearchFormControl"
                    placeholder="{{ 'LABEL.SEARCH_FOR_ORDERS' | translate }}">

                <div *ngIf="globalSearchFormControl?.value?.trim()?.length < 4" class="hint-text">{{
                    'LABEL.ENTER_MIN_FOUR_CHARACTERS' | translate }}</div>
                <mat-spinner *ngIf=" globalSearchFormControl?.value?.trim()?.length > 3 && isLoader " diameter="20"
                    strokeWidth="4"></mat-spinner>
                <!-- <img *ngIf=" globalSearchFormControl?.value?.length > 3 && isLoader " class="loader"
                    src="../../../../../assets/images/main-loader-option-1.gif" /> -->

                <button *ngIf="globalSearchFormControl?.value?.trim()?.length" matSuffix mat-icon-button
                    aria-label="Clear" (click)="clearInputValue()">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>

        </div>

        <!-- <div class="input-warning-section">Please enter at least 3 characters.</div> -->
    </div>

    <div class="beta-section">{{ 'LABEL.BETA' | translate }} </div>
</div>
<!-- overlay part -->

<kendo-popover #myPopover position="bottom" [callout]="false" (shown)="onShown()" (hidden)="onHidden()">
    <ng-template kendoPopoverBodyTemplate>
        <!-- main container -->
        <!-- *ngIf="responseData?.content; else serchingTemplate " -->
        <div *ngIf="!isEmptyAllResponse; else serchingTemplate" class="global-search-container">
            <!-- header section  -->
            <div class="header-container">
                <span (click)="onClosePopUp();">
                    <mat-icon class="close-icon">close</mat-icon>
                </span>
            </div>

            <!-- data section -->
            <div class="body-container">
                <!-- list section -->
                <div class="order-card-section">

                    <!-- result found message  -->

                    <div class="message-section" [formGroup]="filterForm" [ngClass]="{'message-section-loader': isLoader}">
                        <div class="msg" *ngIf="!isLoader; else Skeleton" [@fadeIn]>
                            {{ 'LABEL.SHOWING' | translate }}
                            <span class="highlighter">{{responseData?.totalRecords}}</span>
                            {{ 'LABEL.RECORDS_FOR' | translate }}
                            <span class="highlighter">{{globalSearchFormControl.value}}</span>
                            {{ 'LABEL.FOR_THE_APPLIED_SEARCH_FILTER' | translate }}
                        </div>
                        <div class="filter-box" *ngIf="!isLoader; else SkeletonRectangle" [@fadeIn]>
                            <mat-form-field appearance="outline">
                                <mat-label>{{ 'Order By' | translate }}</mat-label>
                                <mat-select formControlName="orderBy" (selectionChange)="onOrderByChange()"
                                    disableOptionCentering>
                                    <mat-option *ngFor="let type of groupByList" [value]="type.code">
                                        {{type.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                    <!-- order list section -->
                    <!-- *ngIf="orderList.length > 0; else NoResultFound" -->
                    <div class="list-section" #listSectionDiv (scrolled)="loadMoreOrders()" [scrollWindow]="false"
                        infiniteScroll [infiniteScrollDistance]="0.5" [infiniteScrollThrottle]="5">

                        <ng-container *ngIf="isLoader">
                            <order-card *ngFor="let item of [0, 1, 2, 3, 4, 5]" [isLoader]="true"></order-card>
                        </ng-container>

                        <!-- <ng-container *ngFor="let key of orderKeys"> -->
                        <ng-container *ngIf="!isLoader">
                            <order-card [crossDockFacility]="crossDockFacility" *ngFor="let order of orderList"
                                [order]="order" [searchText]="globalSearchFormControl?.value"
                                (openNewTabEvent)="openNewTab($event)"></order-card>
                        </ng-container>
                        <!-- </ng-container> -->
                    </div>
                </div>

                <!-- filter section -->
                <div class="filter-card-section">
                    <span *ngIf="!isLoader; else Skeleton" [@fadeIn] class="filter-lable"> {{ 'LABEL.FILTERS' |
                        translate }}</span>
                    <!-- <global-search-filters [facets]="responseData?.facets"></global-search-filters> -->

                    <div class="global-search-filter-section">

                        <!-- <filter-card *ngFor="let key of filterKeys" 
                            [label]="getLabel(key)" [filterData]="getFacetData(key)"
                                (selectedValues)="onSelectedValues($event)"></filter-card> -->

                        <filter-card [label]="'Order Status'" [filterData]="statusList"
                            [selectedList]="statusSelectedList" (selectedValues)="onSelectedValues($event)"
                            [isLoader]="isLoaderOrderStatus"></filter-card>

                        <filter-card [label]="'Suppliers'" [filterData]="supplierList"
                            [selectedList]="supplierSelectedList" (selectedValues)="onSelectedValues($event)"
                            [isLoader]="isLoaderSupplier"></filter-card>

                        <filter-card [label]="'Freight Term'" [filterData]="freightTermList"
                            [selectedList]="freightTermSelectedList" (selectedValues)="onSelectedValues($event)"
                            [isLoader]="isLoaderFreightTerm"></filter-card>

                        <filter-card [label]="'Buyer User'" [filterData]="buyerUSerList"
                            [selectedList]="buyerUSerSelectedList" (selectedValues)="onSelectedValues($event)"
                            [isLoader]="isLoaderBuyerUser"></filter-card>

                    </div>
                </div>
            </div>
        </div>

        <!-- added additional template which is not mentioned in ticket -->
        <ng-template #serchingTemplate>
            <div class="serching-container-empty">
                <div class="head-section">
                    <div>
                        <span (click)="onClosePopUp();">
                            <mat-icon class="close-icon">close</mat-icon>
                        </span>
                    </div>
                </div>
                <div class="no-result-found-section">
                    <span class="trace-no-results"></span>
                    <span class="big-msg"> {{ 'LABEL.NO_RESULT_FOUND' | translate }}</span>
                    <span class="small-msg"> {{ 'LABEL.WE_COULDNT_FIND_ANY_ITEM_MATCHING_YOUR_SEARCH' | translate
                        }}</span>
                    <span class="go-back-msg" (click)="onClosePopUp()">Close</span>
                </div>
            </div>
        </ng-template>

        <ng-template #NoResultFound>
            <div class="no-data-section">
                <div class="no-data-container">
                    <span class="trace-no-results"></span>
                    <span class="big-msg">{{ 'LABEL.NO_RESULT_FOUND' | translate }}</span>
                    <span class="small-msg">{{ 'LABEL.WE_COULDNT_FIND_ANY_ITEM_MATCHING_YOUR_SEARCH' | translate
                        }}</span>
                </div>
            </div>
        </ng-template>

        <ng-template #Skeleton>
            <kendo-skeleton shape="text" animation="pulse" width="50%"></kendo-skeleton>
        </ng-template>

        <ng-template #SkeletonRectangle>
            <kendo-skeleton shape="rectangle" animation="pulse" [width]="250" height="30px"></kendo-skeleton>
        </ng-template>
    </ng-template>
</kendo-popover>
<!-- </div> -->