/***********************************************************************
* DESCRIPTION :
* Quick Order Search in Buyer and Supplier/Vendor Applications
* NOTES :
* AUTHOR :  Hitesh Ahire       CREATE DATE :    26 March 2024
* CHANGES : Added new component
* REF NO          VERSION   DATE         WHO             DETAIL
* PMB-8552        2.6.0     26/03/2024   Hitesh Ahire    Quick Order Search in Buyer and Supplier/Vendor Applications
************************************************************************/

import {  Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { PopoverAnchorDirective } from '@progress/kendo-angular-tooltip';
import { AppToolbarService } from '../../services/app-toolbar.service';
import { debounceTime, filter, switchMap, catchError, tap } from 'rxjs/operators';
import { PopupComponent } from '@progress/kendo-angular-popup';
import { AppService } from '@procurant/app/app.service';
import { OneNotifyService } from 'one-auth';
import { FEATURE_CODE, MODULE_CODE, SUBSCRIBED_APPS, SUB_STATUS, StorageService } from 'common-lib';
import { HttpResponse } from '@angular/common/http';
import { fadeIn } from '@procurant/sharedcomponent/extras/animation';

@Component({
    selector: 'global-serach',
    templateUrl: './global-serach.component.html',
    styleUrls: ['./global-serach.component.scss'],
    animations: [fadeIn]
})
export class GlobalSerachComponent implements OnInit {
    globalSearchFormControl: FormControl = new FormControl();


    // Use ViewChild to get a reference to the Kendo Popover Anchor directive
    @ViewChild('anchor') anchor: PopoverAnchorDirective;

    @ViewChild('myPopover') myPopover: PopupComponent;
    @ViewChild('listSectionDiv') listSectionDiv: ElementRef;

    public responseData: any;
    public noDataFound = false;
    public filterLists = {};
    public showAll = {};
    public facetsKeys = [];
    // public orderKeys = [];
    // public filterKeys = ['substatus', 'sellercompany', 'freightterm', 'buyerowner'];
    isPopoverOpen: boolean = false;
    public crossDockFacility = false;
    public query = {
        "postatus": [],
        "freightterm": [],
        "supplier": [],
        "buyerowner": [],

        "pageNumber": 0,
        "pageSize": 20,

        "value": "",
        "orderBy": "bestMatch"
    };
    @ViewChild('myPopover') elementToResize!: ElementRef;
    calculatedHeight: number = 0;
    calculatedWidth: number = 0;

    public statusList = [];
    public supplierList = [];
    public freightTermList = []
    public buyerUSerList = [];
    public statusSelectedList = [];
    public supplierSelectedList = [];
    public freightTermSelectedList = []
    public buyerUSerSelectedList = [];
    public isEmptyAllResponse: boolean = false;
    public isLoaderOrderStatus: boolean = false;
    public isLoaderSupplier: boolean = false;
    public isLoaderFreightTerm: boolean = false;
    public isLoaderBuyerUser: boolean = false;
    public isLoader = false;
    public isVisible = true;
    private isReceivedAdjusted: boolean = false;
    @ViewChild('searchInput') searchInput!: ElementRef;
    private readonly RECEIVED_ADJUSTED: string = 'Received - Adjusted';
    private POSTATUS = SUB_STATUS;
    filterForm: FormGroup;
    groupByList = [
        { code: 'bestmatch', name: 'Best Match' },
        { code: 'deliverydate', name: 'Delivery Date' },
    ];

    constructor(private formBuilder: FormBuilder, private appToolbarService: AppToolbarService, private appService: AppService, private elementRef: ElementRef, public notify: OneNotifyService, private sessionStorage: StorageService) { }

    ngOnInit(): void {
        this.resetSelectedList();
        this.query.pageNumber = 0;
        this.updateQueryAndFetchData();
        this.createForm();
    }
    ngAfterViewInit(): void {
        this.calculateSize();
        // Listen to window resize event to recalculate size
        //window.addEventListener('resize', this.calculateSize.bind(this));
        this.getBusinessRules();
    }

    private createForm() {
        this.filterForm = this.formBuilder.group({
            orderBy: ['bestmatch']  // Default value
        });
    }

    // Listen to the escape key press event
    @HostListener('window:keyup.esc')
    onEscKeyUp(): void {
        this.onClosePopUp();
    }

    // Close the popover when clicking outside of it and the main section
    @HostListener('document:click', ['$event'])
    onClick(event: MouseEvent): void {
        const targetElement = event.target as HTMLElement;

        // Check if the clicked element or any of its ancestors match the specified selectors
        const isClickedInside = ['.global-main-section', '.global-search-container', '.k-popover-body', '.filter-box', '.mat-form-field', '.mat-select-panel',   '.cdk-overlay-container']
            .some(selector => targetElement.closest(selector));

        // Close the popover only if clicking outside and the mat-select panel is closed
        if (!isClickedInside) {
            this.onClosePopUp();
        }
    }

    // Listen to the keydown event on the entire document
    // @HostListener('document:keydown', ['$event'])
    // handleKeyboardEvent(event: KeyboardEvent) {
    //     // Convert the key to lowercase and detect Ctrl+F or F3
    //     if ((event.ctrlKey && event.key.toLowerCase() === 'f') || event.key === 'F3') {
    //         event.preventDefault(); // Prevent the default browser find action
    //         this.focusSearchInput();
    //     }
    // }



    // Focus the input when called
    focusSearchInput() {
        this.searchInput.nativeElement.focus();
    }

    public toggleVisibility() {
        this.isVisible = !this.isVisible;
    }
    private getBusinessRules() {
        let request = {
            brRulesRequest: [
                {
                    appCode: SUBSCRIBED_APPS.BUYER,
                    moduleCode: MODULE_CODE.ORDER_MANAGEMENT,
                    featureCode: FEATURE_CODE.ALLOW_BUYERS_CREATE_CROSS_DOCK_ORDERS
                }
            ]
        }

        this.appToolbarService.getAppModuleAccess(request).subscribe({
            next: (response) => {
                this.crossDockFacility = response.some(t => t.featureCode == FEATURE_CODE.ALLOW_BUYERS_CREATE_CROSS_DOCK_ORDERS);
            },
            error: (error) => {
                this.notify.show("No service available.", 'error');
            }
        });
    }

    calculateSize(): void {
        // const element = this.elementToResize.nativeElement;
        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;
        // this.calculatedWidth = screenWidth > 1919.98 ? '90%' : '80%';
        this.calculatedWidth = 0.98 * screenWidth;
        this.calculatedHeight = 0.88 * screenHeight;
    }
    public orderList = [];
    public updateQueryAndFetchData() {
        this.globalSearchFormControl.valueChanges.pipe(
            debounceTime(1000),
            filter(selectedItems => this.istypeHeadTextValid(selectedItems.trim())),
            switchMap((val) => {
                // Show loader before fetching data
                this.isLoader = this.isLoaderOrderStatus = this.isLoaderFreightTerm = this.isLoaderSupplier = this.isLoaderBuyerUser = true;
                this.isEmptyAllResponse = false;
                // Call API to get global search results
                return this.appToolbarService.getGlobalSerachResult(this.query, val);
            }),
        ).subscribe({
            next: (res: HttpResponse<any>) => {
                if (res) {
                    // Clear the existing data
                    this.orderList = [];
                    this.responseData = res.body;
                    this.orderList = this.responseData?.content;

                    // Set the filter data
                    this.setStatusList(this.responseData?.facets?.substatus);
                    this.supplierList = this.responseData?.facets?.sellercompany;
                    this.setFreightTermList(this.responseData?.facets?.freightterm);
                    this.buyerUSerList = this.responseData?.facets?.buyerowner;

                    // After successfully fetching data, hide the loader
                    this.isLoader = this.isLoaderOrderStatus = this.isLoaderFreightTerm = this.isLoaderSupplier = this.isLoaderBuyerUser = false;

                    // Enable background disable flag
                    this.appService.makeBgDisabled = true;

                } else {
                    if (this.globalSearchFormControl.value.trim().length >= 4) {
                        this.appService.makeBgDisabled = true;
                        this.isLoader = this.isLoaderOrderStatus = this.isLoaderFreightTerm = this.isLoaderSupplier = this.isLoaderBuyerUser = false;
                        this.anchor.show(); // Show some UI element
                    }
                    // Clear the data if no response
                    this.responseData = [];
                }

                // Update the response status
                this.getResponseStatus(res.status);
            },
            error: (error) => {
                // In case of error, notify the user
                this.notify.show("No service available.", 'error');
                console.error("Error in search results subscription:", error);

                // Ensure the loader is hidden in case of error
                this.isLoader = this.isLoaderOrderStatus = this.isLoaderFreightTerm = this.isLoaderSupplier = this.isLoaderBuyerUser = false;

                // Disable background and reset data
                this.appService.makeBgDisabled = false;
                this.responseData = [];
                this.isPopoverOpen = false;
            }
        });
    }

    private getResponseStatus(status) {
        this.isEmptyAllResponse = status == 204;
    }

    public onSelectedValues(data) {
        this.isLoader = true;
        switch (data.propertyName) {
            case 'Order Status':
                this.isLoaderFreightTerm = this.isLoaderSupplier = this.isLoaderBuyerUser = true;
                // Check if 'Received' is in the filters array
                if (data.filters.includes(this.POSTATUS.RECEIVED) && this.isReceivedAdjusted) {
                    // Push 'Received - Adjusted' if it is present
                    data.filters.push(this.RECEIVED_ADJUSTED);
                }
                // Assign updated filters to postatus
                this.query.postatus = [...data.filters];

                // this.query.postatus = data.filters;
                break;
            case 'Freight Term':
                this.isLoaderOrderStatus = this.isLoaderSupplier = this.isLoaderBuyerUser = true;
                this.query.freightterm = data.filters;
                break;
            case 'Buyer User':
                this.isLoaderOrderStatus = this.isLoaderSupplier = this.isLoaderFreightTerm = true;
                this.query.buyerowner = data.filters;
                break;
            case 'Suppliers':
                this.isLoaderOrderStatus = this.isLoaderBuyerUser = this.isLoaderFreightTerm = true;
                this.query.supplier = data.filters;
                break;
            // case 'Invoice Status':
            //     this.query.invoicestatus = data.filters;
            //     break;

            // case 'Buyer Company':
            //     this.query.buyercompany = data.filters;
            //     break;

        }
        this.query.pageNumber = 0;
        this.query.orderBy = this.filterForm.get('orderBy').value;

        this.appToolbarService.getGlobalSerachResult(this.query, this.globalSearchFormControl.value.trim()).subscribe({
            next: res => {
                if (res) {
                    this.orderList = [];
                    this.responseData.content = res.body.content;
                    this.responseData.totalRecords = res.body.totalRecords;
                    this.orderList = [...this.orderList, ...this.responseData.content];
                    this.scrollToTop();
                    this.updateFilterData(data.propertyName, res.body);
                    this.isLoader = this.isLoaderOrderStatus = this.isLoaderFreightTerm = this.isLoaderSupplier = this.isLoaderBuyerUser = false;

                } else {
                    this.orderList = [];
                    this.responseData.content = [];
                    this.responseData.totalRecords = 0;
                    this.isLoader = this.isLoaderOrderStatus = this.isLoaderFreightTerm = this.isLoaderSupplier = this.isLoaderBuyerUser = false;
                }
            },
            error: error => {
                this.notify.show("No service available.", 'error');
            }
        });
    }
    private setStatusList(res) {
        if (res) {
            // Use destructuring to find the needed objects once
            let receivedAdjustedItem = res.find(item => item.name === "Received - Adjusted");
            let receivedItem = res.find(item => item.name === "Received");

            if (receivedAdjustedItem) {
                this.isReceivedAdjusted = true;

                // If "Received" is missing, create a new entry for it
                if (!receivedItem) {
                    receivedItem = { name: "Received", count: 0 };
                    res.push(receivedItem);
                }

                // Add the "Received - Adjusted" count to "Received"
                receivedItem.count += receivedAdjustedItem.count;
            } else {
                this.isReceivedAdjusted = false;
            }

            // Filter out "Received - Adjusted" and sort the list alphabetically by name
            this.statusList = res
                .filter(item => item.name !== "Received - Adjusted")
                .sort((a, b) => a.name.localeCompare(b.name));
        }
    }


    private setFreightTermList(res) {
        this.freightTermList = [
            { name: "FOB Carrier", count: 0 },
            { name: "Backhaul", count: 0 },
            { name: "Delivered", count: 0 }
        ];
        if (res) {
            // Iterate over the res list
            for (let item of res) {
                // Find the corresponding entry in freightTermList and update count
                let index = this.freightTermList.findIndex(term => term.name === item.name);
                if (index !== -1) {
                    this.freightTermList[index].count = item.count;
                }
            }
        }
    }

    // filter section 
    public getFacetData(key: string): any[] {
        return this.responseData?.facets[key] || [];
    }

    //update filters base on click
    private updateFilterData(filterName, res) {
        //status 
        // if (this.query.postatus.length > 0) {
        if (filterName != "Order Status" || this.query.postatus.length <= 0) {
            this.setStatusList(res?.facets?.substatus);
            this.statusSelectedList = [];
            if (this.query.postatus.length > 0) {
                this.statusSelectedList = res?.facets?.substatus;
            }
        }
        // else {
        //     this.statusSelectedList = [];
        // }

        //supplier

        // if (this.query.supplier.length > 0) {
        if (filterName != 'Suppliers' || this.query.supplier.length <= 0) {
            this.supplierList = res?.facets?.sellercompany;
            this.supplierSelectedList = [];
            if (this.query.supplier.length > 0) {
                this.supplierSelectedList = res?.facets?.sellercompany;
            }
        }
        // else {
        //     this.supplierSelectedList = [];
        // }

        //buyer user

        // if (this.query.buyerowner.length > 0) {
        if (filterName != 'Buyer User' || this.query.buyerowner.length <= 0) {
            this.buyerUSerList = res?.facets?.buyerowner;
            this.buyerUSerSelectedList = [];
            if (this.query.buyerowner.length > 0) {
                this.buyerUSerSelectedList = res?.facets?.buyerowner;
            }
        }
        // else {
        //     this.buyerUSerSelectedList = [];
        // }

        //freight term

        // if (this.query.freightterm.length > 0) {
        if (filterName != 'Freight Term' || this.query.freightterm.length <= 0) {
            this.setFreightTermList(res?.facets?.freightterm);
            this.freightTermSelectedList = [];
            if (this.query.freightterm.length > 0) {
                this.freightTermSelectedList = res?.facets?.freightterm;
            }
        }
        // else {
        //     this.freightTermSelectedList = [];
        // }
    }

    //scroll paggination
    public loadMoreOrders() {
        // if (this.orderList.length < this.totalRecords) {
        this.query.pageNumber += 1;
        this.getOrderListOnly();
        // }
    }
    //on filter click fetch order list
    private getOrderListOnly() {
        this.appToolbarService.getGlobalSerachResult(this.query, this.globalSearchFormControl.value.trim()).subscribe({
            next: res => {  //TODO:
                this.responseData.content = res.body.content;
                this.responseData.totalRecords = res.body.totalRecords;
                this.orderList = [...this.orderList, ...this.responseData.content];


                // this.orderKeys = Object.keys(this.responseData?.content);
                // this.orderKeys.forEach(key => {
                //     let data = this.getOrderData(key);
                //     // Modify the data by adding a new property
                //     let isShowProduct = this.getSearchByProduct(key);
                //     data.forEach(item => {
                //         item.isShowProduct = isShowProduct; // Add a new property to each item in data
                //     });
                //     this.orderList.push(...data)
                // });

            }
        });
    }

    // Placeholder method, implement istypeHeadTextValid according to your requirements
    private istypeHeadTextValid(selectedItems: any): boolean {
        this.checkInputRest();
        let minNumber = 3;
        this.resetAllFilters();
        if (selectedItems != null && selectedItems.length > minNumber) {
            let txt = selectedItems.trim();
            if (txt != '') {
                this.isLoader = this.isLoaderOrderStatus = this.isLoaderFreightTerm = this.isLoaderSupplier = this.isLoaderBuyerUser = true;
                // Call the popover function here
                this.anchor.show();
                this.query.pageNumber = 0;
                this.query.orderBy = this.filterForm.get('orderBy').value;
                this.scrollToTop();
                return true;
            }
            else {
                this.responseData = [];
                return false;
            }
        } else {
            this.responseData = [];
            return false;
        }
    }

    private resetAllFilters() {
        this.query.postatus = [];
        this.query.freightterm = [];
        this.query.supplier = [];
        this.query.buyerowner = [];
        this.resetSelectedList();
    }

    private resetSelectedList() {
        this.statusSelectedList = this.supplierSelectedList = this.freightTermSelectedList = this.buyerUSerSelectedList = [];
    }

    public onGlobalSerachQueryChange() {
        if (this.globalSearchFormControl.value.trim().length > 3) {
            this.anchor.show();
            this.resetAllFilters();
            this.updateQueryAndFetchData();
        } else {
            this.anchor.hide();
        }
    }

    //global search event catch
    onClosePopUp() {
        this.globalSearchFormControl.patchValue('');
        this.filterForm.get('orderBy').patchValue('bestmatch');
        this.query.pageNumber = 0;
        this.responseData = [];
        this.appService.makeBgDisabled = false;
        this.resetSelectedList();
        this.anchor.hide()
    }


    public getOrderData(key: string): any[] {
        return this.responseData?.content[key] || [];
    }

    public getSearchByProduct(searchList: any) {
        let elementsToCheck = ['buyerproduct.code', 'buyerproduct.description', 'buyerproduct.code.keyword', 'buyerproduct.description.keyword'];
        searchList = searchList.slice(1, -1);
        // Split the string by comma and remove any extra whitespace
        let resultList = searchList.split(',').map(item => item.trim());
        return elementsToCheck.some(item => resultList.includes(item));
    }

    // Function to scroll to top
    public scrollToTop() {
        if (this.listSectionDiv && this.listSectionDiv.nativeElement) {
            this.listSectionDiv.nativeElement.scrollTop = 0;
        }
    }

    public clearInputValue() {
        this.resetSelectedList();
        this.globalSearchFormControl.patchValue('');
    }

    // open order details in new tab
    openNewTab(order) {
        if (order?.postatus == "Draft") {
            const url = ['order/order-list/create', order?.id].join('/');
            window.open(url, '_blank');
        } else {
            const url = ['order/order-list/details', order?.id].join('/');
            this.sessionStorage.remove('loadToOrderDetails'); // (JJ) PMB-8445 Allow buyers to edit POs from Load Details screen
            window.open(url, '_blank');
        }
    }

    public checkInputRest() {
        if (this.globalSearchFormControl.value.trim().length < 4) {
            this.anchor.hide();
            this.appService.makeBgDisabled = false;
        }
    }

    public onHidden() {
        if (document.querySelector(".k-popover") && this.elementToResize) {
            document.querySelector(".k-popover").classList.remove("global-search-k-popover");
            document.querySelector(".k-popover-body").classList.remove("global-search-k-popover-body");
        }
    }
    public onShown() {
        if (document.querySelector(".k-popover") && this.elementToResize) {
            document.querySelector(".k-popover").classList.add("global-search-k-popover");
            document.querySelector(".k-popover-body").classList.add("global-search-k-popover-body");
        }
    }

    public onOrderByChange() {
        this.query.pageNumber = 0;
        this.query.orderBy = this.filterForm.get('orderBy').value;

        this.appToolbarService.getGlobalSerachResult(this.query, this.globalSearchFormControl.value.trim()).subscribe({
            next: res => {
                if (res) {
                    this.orderList = [];
                    this.responseData.content = res.body.content;
                    this.responseData.totalRecords = res.body.totalRecords;
                    this.orderList = [...this.orderList, ...this.responseData.content];
                    this.scrollToTop();
                    // this.updateFilterData(data.propertyName, res.body);
                    this.isLoader = this.isLoaderOrderStatus = this.isLoaderFreightTerm = this.isLoaderSupplier = this.isLoaderBuyerUser = false;

                } else {
                    this.orderList = [];
                    this.responseData.content = [];
                    this.responseData.totalRecords = 0;
                    this.isLoader = this.isLoaderOrderStatus = this.isLoaderFreightTerm = this.isLoaderSupplier = this.isLoaderBuyerUser = false;
                }
            },
            error: error => {
                this.notify.show("No service available.", 'error');
            }
        });

    }
}
