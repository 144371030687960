<div [@fadeIn] *ngIf="!isLoader;else CardSkeleton" class="card-section"
    [ngClass]="{'cancelled-card-section': order?.source?.postatus=='Cancelled'}">
    <div class="head-part">
        <a class="order-no" [ngClass]="{'cancelled-order-no': order?.source?.postatus=='Cancelled'}"
            (click)="openNewTab(order)">{{order?.source?.ponumber}}</a>
        <span class="status" [ngClass]="getStatusClasses(order)">{{ order?.source?.postatus }}
            <!-- <span *ngIf="(order?.source?.crossdockloc && order?.source?.crossdockloc?.name) && (order?.source?.postatus == 'Shipped' || order?.source?.postatus == 'Shipped Modified')">{{'ORDER.LABEL.SHIPPED_TO_CD' | translate}}</span> -->
        </span>
    </div>

    <div class="body-part">
        <div class="column">
            <div class="row">
                <span class="label"> {{ 'NAVBAR.LABEL.SALES_ORDERS' | translate }}: </span>
                <a class="value">
                    {{ (order?.source?.sonumber?.length || 0) > 1 ? 'Multiple' : (order?.source?.sonumber?.[0] || '') }}
                </a>
            </div>
            <div class="row">
                <span class="label routing-label"> {{ 'LABEL.ROUTING' | translate }}: </span>
                <span class="value">{{order?.source?.freightterm}}</span>
            </div>
        </div>

        <div class="column">
            <div class="row">
                <span class="label"> {{ 'LABEL.SUPPLIER' | translate }}: </span>
                <span class="value">{{order?.source?.sellercompany?.name}}</span>
                <span *ngIf="order?.source?.offline" class="ofl-section">OFL</span>
            </div>
            <div class="row">
                <span class="label buyer-lable"> {{ 'LABEL.BUYER' | translate }}: </span>
                <span class="value">{{order?.source?.buyerowner?.name}}</span>
            </div>
        </div>

        <div class="column">
            <div class="row">
                <span class="label"> {{ 'ORDER.LABEL.SHIP_FROM' | translate }}: </span>
                <span class="value"> {{ (order?.source?.shipfromlocs && order?.source?.shipfromlocs.length > 1) ?
                    'Multiple' :
                    ((order?.source?.shipfromlocs && order?.source?.shipfromlocs[0]?.name) || '') }}</span>

            </div>
            <div class="row">
                <span class="label"> {{ 'LABEL.SHIP_DATE' | translate }}: </span>
                <!-- <span class="value">{{order?.source?.shipdate | pipes:'date' }}</span> -->
                <span class="value">{{getShipDate(order)}}</span>
            </div>
        </div>

        <div class="column">
            <div class="row">
                <span class="big-label delivery-date">{{ 'LABEL.DELIVERY_TO' | translate }}: </span>
                <span class="value">{{order?.source?.delivertoloc?.name}}</span>
            </div>
            <div class="row">
                <span class="big-label">{{ 'LABEL.DELIVERY_DATE' | translate }}: </span>
                <span class="value">{{order?.source?.deliverydate | pipes:'date' }}</span>
            </div>
        </div>

        <div class="column" *ngIf="crossDockFacility">
            <div class="row">
                <span class="label">{{ 'LABEL.CD_FACILITY' | translate }}: </span>
                <span class="value">{{order?.source?.crossdockloc?.name}}</span>
            </div>
        </div>
    </div>

    <div class="product-part" *ngIf="isProductSearch(order)">
        <!-- <span class="label">{{ 'LABEL.PRODUCTS_MATCHED_TERM' | translate }}:</span> -->
        <span class="label">Matched Terms:</span>
        <div class="products-list">
            <span class="product-chip" *ngFor="let item of getHighlightList(order) | slice:0:5"
                [innerHTML]="item"></span>
            <span *ngIf="isMoreThanFive">...</span>
            <!-- <span class="product-chip" *ngFor="let item of getProductList(order)">{{item?.code}}</span> -->
        </div>
    </div>
</div>

<ng-template #CardSkeleton>
    <div class="card-section">
        <div class="head-part">
            <kendo-skeleton shape="text" animation="pulse" width="30%"></kendo-skeleton>
            <kendo-skeleton shape="text" animation="pulse" width="10%"></kendo-skeleton>
        </div>
        <div class="body-part">
            <div class="column" *ngFor="let item of [0,1,2,3]">
                <div class="row">
                    <kendo-skeleton shape="text" animation="pulse" width="30%"></kendo-skeleton>
                    <kendo-skeleton shape="text" animation="pulse" width="65%"></kendo-skeleton>
                </div>
                <div class="row">
                    <kendo-skeleton shape="text" animation="pulse" width="25%"></kendo-skeleton>
                    <kendo-skeleton shape="text" animation="pulse" width="60%"></kendo-skeleton>
                </div>
            </div>
        </div>
        <div class="product-part">
            <kendo-skeleton shape="text" animation="pulse" width="25%"></kendo-skeleton>
            <div class="products-list">
                <kendo-skeleton *ngFor="let item of [0,1,2]" shape="circle" animation="pulse" [width]="390"
                    [height]="15"></kendo-skeleton>
            </div>
        </div>
    </div>
</ng-template>