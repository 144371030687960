import { NgModule } from '@angular/core';
import { CommonLibModule, StorageService } from 'common-lib';
import { ComponentService } from './services/component.service';
import { DataModalComponent } from './components/data-modal/data-modal.component';
import { SwiperConfigInterface, SwiperModule, SWIPER_CONFIG } from 'ngx-swiper-wrapper';
// import { TimeAgoPipe } from 'time-ago-pipe';
import { PushNotificationsComponent } from './components/push-notifications/push-notifications.component';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { PushNotificationSuccessComponent } from './components/push-notification-success/push-notification-success.component';
import { NgxUploaderModule } from 'ngx-uploader';
import { CommonDialogComponent } from './components/common-dialog/common-dialog.component';
import { CommonSnackbarComponent } from './components/common-snackbar/common-snackbar.component';
import { CommonNotificationComponent } from './components/common-notification/common-notification.component';
import { ProcurantLoadingComponent } from './components/procurant-loading/procurant-loading.component';
import { SafePipe } from './services/SafePipe';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DateInputsModule } from "@progress/kendo-angular-dateinputs"
import { IntlModule } from '@progress/kendo-angular-intl';
import { PopupModule } from '@progress/kendo-angular-popup';
//(RD)PMB-7380 Modify date selection on the order list screen
import { CustomDatePickerComponent } from './components/custom-date-picker/custom-date-picker.component';
import { CustomDataModalComponent } from './components/custom-data-modal/custom-data-modal.component';
import { CustomeOverlayDesignComponent } from './components/custome-overlay-design/custome-overlay-design.component';
import { ProcurantFooterComponent } from './components/procurant-footer/procurant-footer.component';
import { ProcurantHeaderComponent } from './components/procurant-header/procurant-header.component';
import { ProcurantPopoverComponent } from './components/procurant-popover/procurant-popover.component';
import { ProcurantTooltipComponent } from './components/procurant-tooltip/procurant-tooltip.component';
import { PopoverRendererDirective, ToolTipRendererDirective, TooltipIfTruncatedDirective, YearSelectorDirective } from './services/procurant-directives.directive';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { ProcurantChargesOverlayContainerComponent } from './components/procurant-charges-overlay/procurant-charges-overlay-container/procurant-charges-overlay-container.component';
import { ChargesOverlayListComponent } from './components/procurant-charges-overlay/charges-overlay-list/charges-overlay-list.component';
import { AddChargesOverlayComponent } from './components/procurant-charges-overlay/add-charges-overlay/add-charges-overlay.component';
import { EditChargesOverlayComponent } from './components/procurant-charges-overlay/edit-charges-overlay/edit-charges-overlay.component';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';
import { NgxMaskDirective, NgxMaskModule } from 'ngx-mask';
import { OrderCardComponent } from './components/order-card/order-card.component';
import { FilterCardComponent } from './components/filter-card/filter-card.component';
import { CommonModule } from '@angular/common';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
    observer: true,
    direction: 'horizontal',
    threshold: 50,
    spaceBetween: 5,
    slidesPerView: 1,
    centeredSlides: true
};

@NgModule({
    imports: [
        CommonLibModule,
        SwiperModule,
        NotificationModule,
        NgxUploaderModule,
        FlexLayoutModule,
        ButtonsModule,
        InputsModule,
        DateInputsModule,
        IntlModule,
        PopupModule,
        MatNativeDateModule,
        MatDatepickerModule,
        NgxMaskModule.forRoot(),
        TooltipsModule,
        CommonModule,
        IndicatorsModule
    ],
    declarations: [
        DataModalComponent,
        CustomDataModalComponent,
        // TimeAgoPipe, 
        PushNotificationsComponent,
        PushNotificationSuccessComponent,
        CommonDialogComponent,
        CommonSnackbarComponent,
        CommonNotificationComponent,
        ProcurantLoadingComponent,
        SafePipe,
        //(RD)PMB-7380 Modify date selection on the order list screen
        CustomDatePickerComponent,
        CustomeOverlayDesignComponent,
        ProcurantFooterComponent,
        ProcurantHeaderComponent,
        ProcurantPopoverComponent,
        ProcurantTooltipComponent,
        ToolTipRendererDirective,
        PopoverRendererDirective,
        ProcurantChargesOverlayContainerComponent,
        ChargesOverlayListComponent,
        AddChargesOverlayComponent,
        EditChargesOverlayComponent,
        TooltipIfTruncatedDirective,
        YearSelectorDirective,
        OrderCardComponent,
        FilterCardComponent
    ],
    exports: [
        CommonLibModule,
        DataModalComponent,
        CustomDataModalComponent,
        CustomeOverlayDesignComponent,
        SwiperModule,
        // TimeAgoPipe, 
        CustomeOverlayDesignComponent,
        PushNotificationsComponent,
        PushNotificationSuccessComponent,
        NgxUploaderModule,
        CommonDialogComponent,
        ProcurantLoadingComponent,
        SafePipe,
        FlexLayoutModule,
        ButtonsModule,
        InputsModule,
        DateInputsModule,
        IntlModule,
        PopupModule,
        //(RD)PMB-7380 Modify date selection on the order list screen
        CustomDatePickerComponent,
        //(RD)PMB-7119 Order Details
        ProcurantFooterComponent,
        ProcurantHeaderComponent,
        ProcurantPopoverComponent,
        ProcurantTooltipComponent,
        ToolTipRendererDirective,
        PopoverRendererDirective,
        MatNativeDateModule,
        MatDatepickerModule,
        ProcurantChargesOverlayContainerComponent,
        ChargesOverlayListComponent,
        AddChargesOverlayComponent,
        EditChargesOverlayComponent,
        TooltipIfTruncatedDirective,
        NgxMaskModule,
        OrderCardComponent,
        FilterCardComponent
    ],
    providers: [StorageService, ComponentService,
        {
            provide: SWIPER_CONFIG,
            useValue: DEFAULT_SWIPER_CONFIG
        }
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],
})
export class SharedModule { }


export const UnitCostWarnigModel = Object.freeze({
    value: 1000
});
