/***********************************************************************
* DESCRIPTION :
* This component provides smooth fade-in animations for elements entering and leaving the DOM using Angular's animation triggers.
* NOTES :
* AUTHOR :  Hitesh Ahire       CREATE DATE :    12 Septemer 2024
* CHANGES : Added new component
* REF NO          VERSION   DATE         WHO             DETAIL
* PMB-9201        2.6.0     12/09/2024   Hitesh Ahire      -
************************************************************************/

import { trigger, state, style, animate, transition } from '@angular/animations';

// Animation trigger for fading in an element
export const fadeIn = trigger('fadeIn', [
  transition(':enter', [ // Fade in when the element enters the DOM
    style({ opacity: 0 }),
    animate('600ms ease-in', style({ opacity: 1 }))
  ])
]);

// Animation trigger for expanding and collapsing an element
export const toggleExpand = trigger('toggleExpand', [
  state('collapsed', style({ height: '22vh' })),
  state('expanded', style({ height: '40vh' })),
  transition('collapsed <=> expanded', animate('1s ease'))
]);